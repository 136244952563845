import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>{/* <h1>good day, fellow elders</h1> */}</Layout>
)

export default IndexPage
